






























import {
    User
} from "@/store/user";
import {
    Auth
} from "@/store/auth";
import {
    Component,
    Vue
} from "vue-property-decorator";
import CityDialog from '@/components/Dialog/City.vue'
import FooterAdmin from "@/components/Footers/FooterAdmin.vue"
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import { City } from '@/store/city'
import { Core } from '@/store/core'
import { App } from '@/store/app'
@Component({
    components: {
        FooterAdmin,
        CityDialog,
        UserDropdown,
        IndexNavbar
    },
    computed: {}
})

export default class Admin extends Vue {

    sidebarOpen: boolean = true;
    notificationOpen: boolean = false;
    drawer: boolean = false;
    logined: boolean = false;

    view: any = {
        atTopOfPage: true
    }

    get mobile() {
        return this.$device.mobile
    }

    async created() {
        console.log(this.$router.options.routes)
        window.addEventListener('scroll', this.handleScroll);
        await Auth.checkToken();
        await App.onLoad();
        await City.getFullCity();
        await Core.getFullChoice();
        await App.offLoad();
        this.logined = await User.loadUser();
        if (User.routeUser == '/' || User.routeUser == '/admin/home') {

            await this.$router.replace(User.routeUser)
        }
    }

    handleScroll() {
        // when the user scrolls, check the pageYOffset
        if (window.pageYOffset > 0) {
            // user is scrolled
            if (this.view.atTopOfPage) this.view.atTopOfPage = false
        } else {
            // user is at top of page
            if (!this.view.atTopOfPage) this.view.atTopOfPage = true
        }
    }
}
